import ckeditorconfig from "@/libs/ckeditorconfig";

export default [
  {
    key: "bannerId",
    initKey: "banner",
    label: "field.banner",
    type: "async-single-image",
    path: "brand-directory",
    pw: 2000,
    ph: 768,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "brandCategoryId",
    label: "field.brandCategory",
    rules: "required",
    type: "asyn-single-selection",
    repository: "brandCategory",
    selectionKey: "id",
    selectionLabel: "nameEn",
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    label: "breadcrumb.brandDirectoryContentEn",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "titleEn",
    label: "field.titleEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    rules: "required|max:10000",
    type: "ck-editor",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },
  {
    key: "contactInformationEn",
    label: "field.contactInformationEn",
    rules: "required|max:10000",
    type: "ck-editor",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },
  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    rules: "max:100",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    rules: "max:10000",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.brandDirectoryContentKm",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "titleKm",
    label: "field.titleKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    rules: "required|max:10000",
    type: "ck-editor",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },
  {
    key: "contactInformationKm",
    label: "field.contactInformationKm",
    rules: "required|max:10000",
    type: "ck-editor",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },
  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    rules: "max:100",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    rules: "max:10000",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.brandDirectoryGallery",
    icon: "ImageIcon",
    type: "divider",
    hasDescriptionDimension: true,
    pw: 1920,
    ph: 880,
  },
  {
    key: "brandDirectoryGalleries",
    hideLabel: true,
    cols: 12,
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
